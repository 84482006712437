import React from 'react'
import Layout from '../components/layout'
import pic22 from '../assets/images/p22.jpg'

const Proximamente = () => (
  <Layout>
    <div id="main" className="alt">
        <section id="one">
            <div className="inner">
              <img src={pic22} className='image fit' />
                <h1>Próximamente!!!</h1>
                <p>Inténtalo luego. Gracias!.</p>
            </div>
        </section>
    </div>
  </Layout>
)

export default Proximamente
