import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

const Menu = (props) => (
    <nav id="menu">
        <div className="inner">
            <ul className="links">
                <li><Link onClick={props.onToggleMenu} to="/">Home</Link></li>
                <li><Link onClick={props.onToggleMenu} to="/revelarte">RevelARTE</Link>
                <ul>
                    <li><Link onClick={props.onToggleMenu} to="/creatividad">Creatividad</Link></li>
                    <li><Link onClick={props.onToggleMenu} to="/elarte">Arte</Link></li>
                    <li><Link onClick={props.onToggleMenu} to="/saludmental">Salud Mental</Link></li>
 

                    </ul>
                </li>
                <li><Link onClick={props.onToggleMenu} to="/talleres">Talleres</Link>
                <ul>
                    <li><Link onClick={props.onToggleMenu} to="/talleres"> TALLER EMOCIONES Y CREATIVIDAD</Link></li>
                     
                    </ul>
                </li>

                <li><Link onClick={props.onToggleMenu} to="/galeria">Galeria</Link></li>



               
             </ul>
            {/* <ul className="actions vertical">
                <li><a href="#" className="button special fit">Get Started</a></li>
                <li><a href="#" className="button fit">Log In</a></li>
            </ul> */}
        </div>
        <a className="close" onClick={props.onToggleMenu} href="javascript:;">Close</a>
    </nav>
)

Menu.propTypes = {
    onToggleMenu: PropTypes.func
}

export default Menu
