import React from 'react'

const Contact = (props) => (
    <section id="contact">
        <div className="inner">
           
         
            <section className="    ">
            <h3>Contáctanos</h3>
                <section>
                 


                    <ul className="icons">
                    <li><a href="mailto:proyectorevelarteok@gmail.com" target='_blank' className="icon alt fa-envelope"><span className="label">Mail</span> proyectorevelarteok@gmail.com</a></li>

                <li><a href="https://www.facebook.com/RevelArte-Herramientas-para-la-creatividad-104232234762224" className="icon alt fa-facebook"><span className="label">Facebook</span> RevelArte </a></li>
                <li><a href="https://www.instagram.com/proyectorevelarteok" className="icon alt fa-instagram"><span className="label">Instagram</span> proyectorevelarteok</a></li>
              </ul>
                </section>
              
            </section>
        </div>
    </section>
)

export default Contact
