import React from 'react'

const Footer = (props) => (
    <footer id="footer">
        <div className="inner">
          
            <ul className="copyright">
                <li>&copy; Revelarte 2020</li><li></li>
            </ul>
        </div>
    </footer>
)

export default Footer
